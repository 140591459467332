@import "colors";
@import "spacing";

.dropTarget {
    min-height: 1px; }

.highlightDropTarget.isOver.dropTarget {
    border: 1px dashed $dk-blue;
    min-height: $std-space * 3; }

.dragIcon {
    opacity: .4;
    cursor: grab; }

.isDragging {
    opacity: .6; }
